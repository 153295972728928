import React from 'react';
import { Box, TextField, MenuItem, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function Step1Profile({ formikProps }: { formikProps: any }) {
  const { values, errors, touched, handleChange, setFieldValue, isSubmitting } = formikProps;

  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ mb: 1 }}>
        Set Up Your Profile
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
        Provide all of the below information related to yourself and designation to the children you'll enroll.
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          fullWidth
          name="firstName"
          label="First name"
          value={values.firstName}
          onChange={handleChange}
          error={touched.firstName && Boolean(errors.firstName)}
          helperText={touched.firstName && errors.firstName}
        />

        <TextField
          fullWidth
          name="lastName"
          label="Last name"
          value={values.lastName}
          onChange={handleChange}
          error={touched.lastName && Boolean(errors.lastName)}
          helperText={touched.lastName && errors.lastName}
        />

        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            fullWidth
            name="email"
            label="Email address"
            type="email"
            value={values.email}
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            name="phone"
            label="Phone number"
            value={values.phone}
            onChange={handleChange}
            error={touched.phone && Boolean(errors.phone)}
            helperText={touched.phone && errors.phone}
          />
        </Box>

        <TextField
          select
          fullWidth
          name="designation"
          label="Designation"
          value={values.designation}
          onChange={handleChange}
          error={touched.designation && Boolean(errors.designation)}
          helperText={touched.designation && errors.designation}
        >
          <MenuItem value="parent">Parent</MenuItem>
          <MenuItem value="guardian">Guardian</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </TextField>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Birthday"
            value={values.birthday}
            onChange={(value) => setFieldValue('birthday', value)}
            slotProps={{
                textField: {
                  fullWidth: true,
                  error: formikProps.touched.birthday && Boolean(formikProps.errors.birthday),
                  helperText: formikProps.touched.birthday && formikProps.errors.birthday,
                },
              }}
          />
        </LocalizationProvider>

        <LoadingButton
          fullWidth
          variant="contained"
          type="submit"
          loading={isSubmitting}
          sx={{
            mt: 2,
            bgcolor: '#ff4d4f',
            '&:hover': {
              bgcolor: '#ff7875',
            },
          }}
        >
          Next
        </LoadingButton>
      </Box>
    </Box>
  );
}

